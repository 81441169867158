import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = {
  width: "46",
  height: "42",
  viewBox: "0 0 46 42",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createStaticVNode("<g id=\"Group 166\"><path id=\"Vector\" d=\"M15.5451 33.9668H0.5V41.1254H15.5451V33.9668Z\" fill=\"#212529\"></path><path id=\"Vector_2\" d=\"M42.9081 29.5234H34.0195V41.1268H42.9081V29.5234Z\" fill=\"#212529\"></path><path id=\"Vector_3\" d=\"M30.59 30.791H15.5449V41.127H30.59V30.791Z\" fill=\"#212529\"></path><path id=\"Vector_4\" d=\"M45.4995 27.4391V19.2283C45.4995 15.3419 42.349 12.1914 38.4626 12.1914C34.5763 12.1914 31.4258 15.3419 31.4258 19.2282V27.4391H45.4995Z\" fill=\"#F06F02\"></path><path id=\"Vector_5\" d=\"M12.6171 17.5957H3.42969V30.7856H12.6171V17.5957Z\" fill=\"#212529\"></path><path id=\"Vector_6\" d=\"M27.6601 14.543H18.4727V27.7329H27.6601V14.543Z\" fill=\"#212529\"></path><circle id=\"Ellipse 77\" cx=\"38.4624\" cy=\"6.05032\" r=\"5.17727\" fill=\"#F06F02\"></circle><path id=\"Rectangle 1136\" d=\"M1.02344 18.2246L8.02113 11.2269L15.0188 18.2246H8.02113H1.02344Z\" fill=\"#212529\"></path><path id=\"Rectangle 1137\" d=\"M16.0703 15.1719L23.068 8.17419L30.0657 15.1719H23.068H16.0703Z\" fill=\"#212529\"></path></g>", 1)
  ])))
}
export default { render: render }